import React from 'react';
import RetroGrid from './components/magicui/retro-grid';
import './App.css';
import './Button.css';

function App() {
  /**
   * Renders the navigation bar component.
   * @returns {JSX.Element} The JSX element representing the navigation bar.
   */
  const renderNav = (): JSX.Element => {
    return (
      <nav className="app-nav">
        <div className="app-nav-content">
          <h1 className="app-title nonselectable">THE PARKOUR COMPANY</h1>
          <a href="https://data.parkourcompany.com" className="default-button">
            Get started
          </a>
        </div>
      </nav>
    );
  };

  /**
   * Renders the main content of the application.
   * @returns {JSX.Element} The JSX element representing the main content.
   */
  const renderMain = (): JSX.Element => {
    return (
      <main className="app-main">
        <RetroGrid className="z-0" />
        <p className="app-main-heading nonselectable">
          Data for Autonomous Humanoid Robots
        </p>
        <p className="app-main-description nonselectable">
          Diverse whole-body motion control data of parkour movements from the
          world's best athletes.
        </p>
      </main>
    );
  };

  /**
   * Renders the footer component.
   * @returns {JSX.Element} The JSX element representing the footer.
   */
  const renderFooter = (): JSX.Element => {
    return (
      <div className="app-footer">
        <div className="app-footer-content">
          <div className="app-footer-links">
            <h3 className="app-footer-section-header">Contact</h3>
            <a
              href="mailto:hello@parkourcompany.com"
              className="app-footer-contact-item"
              target="_blank"
              rel="noopener noreferrer"
            >
              Email
            </a>
            <a
              href="https://twitter.com/parkour_company"
              className="app-footer-contact-item"
              target="_blank"
              rel="noopener noreferrer"
            >
              Twitter
            </a>
            <a
              href="https://www.instagram.com/theparkourcompany/"
              className="app-footer-contact-item"
              target="_blank"
              rel="noopener noreferrer"
            >
              Instagram
            </a>
          </div>
          <div className="copyright-section">
            <span className="app-footer-copyright">
              &copy; {new Date().getFullYear()} The Parkour Company
            </span>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="app-container">
      {renderNav()}
      {renderMain()}
      {renderFooter()}
    </div>
  );
}

export default App;
